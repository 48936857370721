import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'
import Callbacks from './callbacks'
import { HookUtils } from '@front/volcanion/utils'
import { Loader } from '@front/squirtle'

const withForm = Component => ({
  isOrderDuplicate,
  order_id,
  setIsWaiting,
  isWaiting,
  disableHeader = false,
  disableHistory,
  disableMap,
  headerLabelProps,
  form_name = 'order_form',
}) => {
  const CODE_INTERNATIONAL = hooks.useRelayConstant('CODE_INTERNATIONAL')
  const getEstimation = hooks.useEstimationSelector()
  const [domain, domain_status] = hooks.useDetailedCurrentDomain({ mutator: 'domain_id' })
  const [order, order_status] = hooks.useModel('order', [order_id], { single: true })
  const modify_fields = _.includes(['created', 'standby'], order?.status) || isOrderDuplicate ? [] : ['customer_ref', 'order_doc', 'info.src_comment', 'info.dest_comment', 'driver_comment']

  const formProps = {
    name: form_name,
    model_name: 'order',
    record_id: order_id,
    getEmptyState: useCallback(Callbacks.getEmptyStateHandler(domain), [domain]),
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(isOrderDuplicate), [isOrderDuplicate]),
    formToRecord: useCallback(Callbacks.formToRecordHandler(CODE_INTERNATIONAL, getEstimation), [CODE_INTERNATIONAL, getEstimation]),
    formToOptions: useCallback(Callbacks.formToOptionsHandler(), []),
    modify_fields,
    populate: [
      'do.user_companies.companyuser_services.companyservice.contract',
      'do.info',
      'do.customerinfo',
      'do.auth',
      'commercial_package',
      'source.poi.type',
      'destination.poi',
      'service.contract',
      'info',
      'loads',
      'driver_estimation',
      'quotation',
      'driver_opts',
      'vehicle_opts',
      'specific_parameter',
      'job.payment',
      'job.orders',
      'assigned_transport.payment',
      'assigned_transport.info',
      'assigned_transport.driver',
      'reservationinfos',
      'mode.config',
      'transaction',
    ],
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
    includeReadOnly: true,
    modifyChangesOnly: !isOrderDuplicate,
    baseRoute: isOrderDuplicate ? 'order' : null,
  }
  const mergedProps = {
    disableHeader,
    disableHistory,
    disableMap,
    headerLabelProps,
    setIsWaiting,
    isWaiting
  }
  return (
    <Loader isLoading={HookUtils.getLoadingState([domain_status, order_status])}>
      <ModelForm {...formProps}>
        <Component {...mergedProps} />
      </ModelForm>
    </Loader>
  )
}

export default withForm
